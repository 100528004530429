import type mapboxgl from "mapbox-gl";
import type maplibregl from "maplibre-gl";
import { DEFAULT_BOUNDS } from "../Map/MapProvider";

// Creating a mapbox/maplibre control for resetting the map
// Example followed for this implementation: https://github.com/mapbox/mapbox-gl-js/blob/main/src/ui/control/navigation_control.js
class ResetMapControl implements mapboxgl.IControl, maplibregl.IControl {
  map?: mapboxgl.Map | maplibregl.Map;

  container: HTMLElement;

  constructor(props: { disabled: boolean }) {
    this.container = document.createElement("div");
    this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
    this.container.addEventListener("contextmenu", (e: MouseEvent) =>
      e.preventDefault(),
    );

    const resetMapButton = document.createElement("button");
    resetMapButton.className = "mapboxgl-ctrl-reset-map";
    resetMapButton.type = "button";
    resetMapButton.disabled = props.disabled;
    resetMapButton.title = "Re-centre";
    resetMapButton.addEventListener("click", () =>
      this.map?.fitBounds(DEFAULT_BOUNDS, {
        duration: 200,
      }),
    );

    const el = document.createElement("span");
    el.className = "mapboxgl-ctrl-icon";
    // "Globe" icon from https://fonts.google.com/icons?selected=Material%20Symbols%20Outlined%3Aglobe%3AFILL%400%3Bwght%40400%3BGRAD%400%3Bopsz%4024
    el.style.backgroundImage = `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333' height='20px' viewBox='0 -960 960 960'%3E%3Cpath d='M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-7-.5-14.5T799-507q-5 29-27 48t-52 19h-80q-33 0-56.5-23.5T560-520v-40H400v-80q0-33 23.5-56.5T480-720h40q0-23 12.5-40.5T563-789q-20-5-40.5-8t-42.5-3q-134 0-227 93t-93 227h200q66 0 113 47t47 113v40H400v110q20 5 39.5 7.5T480-160Z'/%3E%3C/svg%3E")`;
    el.ariaHidden = "true";
    resetMapButton.appendChild(el);

    this.container.appendChild(resetMapButton);
  }

  onAdd(map: mapboxgl.Map | maplibregl.Map): HTMLElement {
    this.map = map;
    return this.container;
  }

  onRemove() {
    this.container.remove();
    this.map = undefined;
  }
}

export default ResetMapControl;
